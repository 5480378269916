let furnitureCategories = [
    {
        id: 1,
        cateogry: "Living Room",
        article: "Sofa",
        materials: ["Wood", "Fabric", "Leather"],
        colors: ["Black", "White", "Brown", "Grey", "Blue", "Red", "Other"],
        dimensions: ["Small (2 seats)", "Medium (3 seats)", "Large (4 seats or more)"],
        styles: ["Modern", "Classic", "Scandinavian", "Industrial", "Vintage", "Traditional"],
        features: ["Convertible", "Corner Sofa", "With Chaise", "Fixed", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 2,
        cateogry: "Living Room",
        article: "Coffee Table",
        materials: ["Wood", "Glass", "Metal", "Marble"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Scandinavian", "Industrial", "Vintage", "Traditional"],
        features: ["With Drawers", "With Shelves", "Extendable", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 3,
        cateogry: "Living Room",
        article: "Armchair",
        materials: ["Wood", "Fabric", "Leather"],
        colors: ["Black", "White", "Brown", "Grey", "Blue", "Red", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Scandinavian", "Industrial", "Vintage", "Traditional"],
        features: ["Recliner", "With Footstool", "Swivel", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 4,
        cateogry: "Living Room",
        article: "Bookcase",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Scandinavian", "Industrial", "Vintage", "Traditional"],
        features: ["With Doors", "With Drawers", "Modular", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 5,
        cateogry: "Living Room",
        article: "TV Stand",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Scandinavian", "Industrial", "Vintage", "Traditional"],
        features: ["With Drawers", "With Shelves", "With Doors", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 6,
        cateogry: "Living Room",
        article: "Pouf",
        materials: ["Fabric", "Leather", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Scandinavian", "Industrial", "Vintage", "Traditional"],
        features: ["With Storage", "With Footrest", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 7,
        cateogry: "Bedroom",
        article: "Bed",
        materials: ["Wood", "Metal", "Fabric"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Single", "Double", "King Size", "Queen Size"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Drawers", "With Headboard", "With Slats", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 8,
        cateogry: "Bedroom",
        article: "Wardrobe",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Mirror", "With Closet", "With Shelves", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 9,
        cateogry: "Bedroom",
        article: "Dresser",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Drawers", "With Shelves", "With Mirror", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 10,
        cateogry: "Bedroom",
        article: "Nightstand",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Drawers", "With Shelves", "With Integrated Light", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 11,
        cateogry: "Bedroom",
        article: "Bunk Bed",
        materials: ["Wood", "Metal"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Ladder", "With Drawers", "With Built-in Desk", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 12,
        cateogry: "Bedroom",
        article: "Dressing Table",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Mirror", "With Drawers", "With Shelves", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 13,
        cateogry: "Kitchen",
        article: "Kitchen Table",
        materials: ["Wood", "Glass", "Metal", "Marble"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Rustic", "Industrial", "Vintage", "Traditional"],
        features: ["Extendable", "With Extensions", "With Drawers", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 14,
        cateogry: "Kitchen",
        article: "Kitchen Chair",
        materials: ["Wood", "Metal", "Plastic", "Fabric"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Rustic", "Industrial", "Vintage", "Traditional"],
        features: ["Stackable", "With Armrests", "With Cushions", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 15,
        cateogry: "Kitchen",
        article: "Kitchen Island",
        materials: ["Wood", "Glass", "Metal", "Marble"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Rustic", "Industrial", "Vintage", "Traditional"],
        features: ["With Sink", "With Cooktop", "With Shelves", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 16,
        cateogry: "Kitchen",
        article: "Buffet",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Rustic", "Industrial", "Vintage", "Traditional"],
        features: ["With Doors", "With Drawers", "With Shelves", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 17,
        cateogry: "Kitchen",
        article: "Bar Table",
        materials: ["Wood", "Glass", "Metal", "Marble"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Rustic", "Industrial", "Vintage", "Traditional"],
        features: ["With Stools", "With Shelves", "With Drawers", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 18,
        cateogry: "Kitchen",
        article: "Bar Stool",
        materials: ["Wood", "Metal", "Plastic", "Fabric"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Rustic", "Industrial", "Vintage", "Traditional"],
        features: ["Stackable", "With Armrests", "With Cushions", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 19,
        cateogry: "Bathroom",
        article: "Bathroom Cabinet",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Mirror", "With Closet", "With Shelves", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 20,
        cateogry: "Bathroom",
        article: "Bathroom Mirror",
        materials: ["Glass", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Lighting", "With Built-in Cabinet", "With Shelves", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 21,
        cateogry: "Bathroom",
        article: "Sink",
        materials: ["Ceramic", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Cabinet", "With Shelves", "With Drawers", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 22,
        cateogry: "Bathroom",
        article: "Towel Rack",
        materials: ["Metal", "Wood", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Heating", "With Shelves", "With Hooks", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 23,
        cateogry: "Bathroom",
        article: "Bathroom Shelf",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Doors", "With Drawers", "With Mirror", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 24,
        cateogry: "Office",
        article: "Desk",
        materials: ["Wood", "Metal", "Glass"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Drawers", "With Shelves", "With Integrated Light", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 25,
        cateogry: "Office",
        article: "Office Chair",
        materials: ["Wood", "Metal", "Fabric"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["Ergonomic", "With Armrests", "With Wheels", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 26,
        cateogry: "Office",
        article: "Office Bookcase",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Doors", "With Drawers", "Modular", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 27,
        cateogry: "Office",
        article: "Filing Cabinet",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Drawers", "With Shelves", "With Lock", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 28,
        cateogry: "Office",
        article: "Conference Table",
        materials: ["Wood", "Glass", "Metal"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["Extendable", "With Drawers", "With Shelves", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 29,
        cateogry: "Appliances",
        cateogry: "Appliances",
        article: "Refrigerator",
        materials: ["Stainless Steel", "Plastic"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Freezer", "With Water Dispenser", "With Ice Dispenser", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 30,
        cateogry: "Appliances",
        article: "Dishwasher",
        materials: ["Stainless Steel", "Plastic"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Dryer", "With Eco Programs", "With Cutlery Basket", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 31,
        cateogry: "Appliances",
        article: "Oven",
        materials: ["Stainless Steel", "Plastic"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Grill", "With Convection", "With Cooking Programs", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 32,
        cateogry: "Appliances",
        article: "Microwave",
        materials: ["Stainless Steel", "Plastic"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Grill", "With Defrost", "With Cooking Programs", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 33,
        cateogry: "Appliances",
        article: "Television",
        materials: ["Plastic", "Metal"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small (32\")", "Medium (43\")", "Large (55\" and above)"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["Smart TV", "4K", "HDR", "With Wi-Fi", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 34,
        cateogry: "Appliances",
        article: "Washing Machine",
        materials: ["Stainless Steel", "Plastic"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Dryer", "With Eco Programs", "With Large Load Capacity", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 35,
        cateogry: "Appliances",
        article: "Dryer",
        materials: ["Stainless Steel", "Plastic"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Eco Programs", "With Large Load Capacity", "With Fast Drying", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 36,
        cateogry: "Appliances",
        article: "Freezer",
        materials: ["Stainless Steel", "Plastic"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Compartments", "With Drawers", "With Fast Freezing", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 37,
        cateogry: "Appliances",
        article: "Vacuum Cleaner",
        materials: ["Plastic", "Metal"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["Cordless", "With Bag", "With HEPA Filter", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 38,
        cateogry: "Appliances",
        article: "Food Processor",
        materials: ["Plastic", "Metal"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Blender", "With Chopper", "With Steamer", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 39,
        cateogry: "Appliances",
        article: "Coffee Maker",
        materials: ["Plastic", "Metal"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Grinder", "With Capsules", "With Milk Frother", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 40,
        cateogry: "Appliances",
        article: "Air Conditioner",
        materials: ["Plastic", "Metal"],
        colors: ["Black", "White", "Silver", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Dehumidifier", "With Air Purifier", "With Timer", "Other"],
        brands: ["Samsung", "LG", "Local", "Other"]
    },
    {
        id: 41,
        cateogry: "Other",
        article: "Garden Furniture",
        materials: ["Wood", "Plastic", "Metal"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Cushions", "With Built-in Table", "With Umbrella", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 42,
        cateogry: "Other",
        article: "Storage Unit",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Drawers", "With Shelves", "With Doors", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 43,
        cateogry: "Other",
        article: "Game Room Furniture",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Shelves", "With Drawers", "With Built-in Bench", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    },
    {
        id: 44,
        cateogry: "Other",
        article: "Library Furniture",
        materials: ["Wood", "Metal", "Plastic"],
        colors: ["Black", "White", "Brown", "Grey", "Other"],
        dimensions: ["Small", "Medium", "Large"],
        styles: ["Modern", "Classic", "Contemporary", "Industrial", "Vintage", "Traditional"],
        features: ["With Doors", "With Drawers", "Modular", "Other"],
        brands: [
            "IKEA",
            "Local",
            "Conforama",
            "Maisons du Monde",
            "Roche Bobois",
            "Leroy Merlin",
            "Habitat",
            "But",
            "Darty",
            "Boulanger",
            "Alinéa",
            "La Redoute Intérieurs",
            "Fly",
            "Other"
        ]
    }
]


export { furnitureCategories }